import Vue from 'vue';
import App from '@/App.vue';
import store from '@/store';
import router from '@/router';
import permission from '@/utils/permission';
import { MAP_KEY } from '@/config/setting';
import EleAdmin from 'ele-admin';
import VueClipboard from 'vue-clipboard2';
import i18n from '@/i18n';   //语言-废弃
import '@/styles/index.scss';

Vue.config.productionTip = false;
Vue.use(EleAdmin, {
  size: store.state.theme?.sysGlobalSize ?? 'medium',
  response: { dataName: 'list' },
  mapKey: MAP_KEY,
  i18n: (key, value) => i18n.t(key, value)   //语言-废弃
});
Vue.use(permission);
Vue.use(VueClipboard);

import elmDrawer from '@/components/DrawerModal/index.vue'   //ant抽屉
Vue.component('elm-drawer', elmDrawer)

import { utilsfn } from '@/scripts/utilsfn';
Vue.prototype.$utilsfn = utilsfn;
import htmlToPdf from '@/scripts/htmlToPdf';
Vue.use(htmlToPdf)

window._AMapSecurityConfig = {
  securityJsCode: '14c1f3ca179ac9deea5bb465069eff05'
}


new Vue({
  router,
  store,
  i18n,     //语言-废弃
  render: (h) => h(App)
}).$mount('#app');
