/* 登录页面 */
export default {
  title: '用户登录',
  username: '请输入登录账号',
  password: '请输入登录密码',
  code: '请输入验证码',
  remember: '记住密码',
  forget: '忘记密码',
  login: '登录',
  loading: '登录中'
};
