<!-- 修改密码弹窗 -->
<template>
  <elm-drawer width="420px" title="修改密码" :visible="visible" :append-to-body="true" :close-on-click-modal="false" centered @update:visible="updateVisible" @closed="onClose" position="center" top="0px">
    <el-form ref="form" :model="form" :rules="rules" label-width="82px" @keyup.enter.native="save">
      <el-form-item label="旧密码:" prop="pwdOld">
        <el-input show-password v-model="form.pwdOld" placeholder="请输入旧密码" />
      </el-form-item>
      <el-form-item label="新密码:" prop="pwdNew">
        <el-input show-password v-model="form.pwdNew" placeholder="请输入新密码" />
      </el-form-item>
      <el-form-item label="确认密码:" prop="confirmPassword">
        <el-input show-password v-model="form.confirmPassword" placeholder="请再次输入新密码" />
      </el-form-item>
    </el-form>
    <template v-slot:footer>
      <el-button @click="updateVisible(false)">取消</el-button>
      <el-button type="primary" :loading="loading" :disabled="loading" @click="save">确定</el-button>
    </template>
  </elm-drawer>
</template>

<script>
import { updatePassword } from '@/api/layout';
export default {
  props: {
    visible: Boolean
  },
  data () {
    return {
      // 按钮 loading
      loading: false,
      // 表单数据
      form: {
        pwdOld: '',
        pwdNew: '',
        confirmPassword: ''
      },
      // 表单验证
      rules: {
        pwdOld: [{ required: true, message: '请输入旧密码', trigger: 'blur' }],
        // password: [{ required: true, message: '请输入新密码', trigger: 'blur' }],
        pwdNew: [{ required: true, trigger: 'blur', validator: (_rule, value, callback) => { if (!value) return callback(new Error('请输入新密码')); var regex = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[~!@#$%^&*()_+?])[0-9a-zA-Z~!@#$%^&*()_?]{10,20}$/; if (regex.test(value)) { callback(); if (this.form.confirmPassword !== '') this.$refs.form.validateField('confirmPassword'); } else { callback(new Error('请输入10到20位的 英文大小写、数字、特殊符号 三种混合密码')); } } }],
        // password2: [{ required: true, message: "请再次输入新密码", trigger: "blur" }],
        confirmPassword: [{ required: true, trigger: 'blur', validator: (_rule, value, callback) => { if (!value) return callback(new Error('请输入确认密码')); var regex = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[~!@#$%^&*()_+?])[0-9a-zA-Z~!@#$%^&*()_?]{10,20}$/; if (regex.test(value)) { if (value !== this.form.pwdNew) return callback(new Error('两次输入密码不一致!')); callback(); } else { callback(new Error('请输入10到20位的 英文大小写、数字、特殊符号 三种混合密码')); } } }],
      }
    };
  },
  methods: {
    /* 修改 visible */
    updateVisible (value) {
      this.$emit('update:visible', value);
    },
    /* 保存修改 */
    save () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          updatePassword(this.form).then((msg) => {
            this.loading = false;
            this.$message.success(msg);
            this.updateVisible(false);
          }).catch((e) => {
            this.loading = false;
            this.$message.error(e.message);
          });
        } else {
          return false;
        }
      });
    },
    /* 关闭回调 */
    onClose () {
      this.form = {
        pwdOld: '',
        pwdNew: '',
        confirmPassword: ''
      };
      this.$refs.form.resetFields();
      this.loading = false;
    }
  }
};
</script>
