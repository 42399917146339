<!-- 顶栏消息通知 -->
<template>
  <el-popover :width="330" trigger="click" v-model="visible" class="ele-notice-group" transition="el-zoom-in-top"
    popper-class="ele-notice-pop">
    <template v-slot:reference>
      <div class="ele-notice-group">
        <el-badge :value="unreadNum" :hidden="!unreadNum">
          <i class="el-icon-bell"></i>
        </el-badge>
      </div>
    </template>
    <el-tabs v-model="active" @tab-click="tiveChange">
      <el-tab-pane name="notice" :label="noticeTitle">
        <div class="ele-notice-list ele-scrollbar-mini">
          <div v-for="(item, index) in notice" :key="index" class="ele-notice-item">
            <div class="ele-cell ele-notice-item-wrapper" @click="viewDetail(item)">
              <i :class="['el-icon-chat-dot-square','ele-notice-item-icon']"></i>
              <div class="ele-cell-content">
                <div class="ele-elip">{{ item.messageTitle }}</div>
                <div class="ele-text-secondary ele-elip">{{$util.toDateString(item.sendTime)}}</div>
              </div>
            </div>
            <el-divider />
          </div>
        </div>
        <div v-if="notice.length" class="ele-cell ele-notice-actions">
          <!-- <div class="ele-cell-content" @click="clearNotice">清空通知</div>
          <el-divider direction="vertical" class="line-color-light" /> -->
          <router-link to="/system/messageList" class="ele-cell-content">查看更多</router-link>
        </div>
        <ele-empty v-if="!notice.length" text="已查看所有信息" />
      </el-tab-pane>
      <el-tab-pane name="letter" :label="letterTitle">
        <div class="ele-notice-list ele-scrollbar-mini">
          <div v-for="(item, index) in notice" :key="index" class="ele-notice-item">
            <div class="ele-cell ele-notice-item-wrapper" @click="viewDetail(item)">
              <i :class="['el-icon-chat-dot-square', 'ele-notice-item-icon']"></i>
              <div class="ele-cell-content">
                <div class="ele-elip">{{ item.messageTitle }}</div>
                <div class="ele-text-secondary ele-elip">{{ $util.toDateString(item.sendTime) }}</div>
              </div>
            </div>
            <el-divider />
          </div>
        </div>
        <div v-if="notice.length" class="ele-cell ele-notice-actions">
          <!-- <div class="ele-cell-content" @click="clearLetter">清空私信</div>
          <el-divider direction="vertical" class="line-color-light" /> -->
          <router-link to="/system/messageList" class="ele-cell-content">查看更多</router-link>
        </div>
        <ele-empty v-if="!notice.length" text="已查看所有信息" />
      </el-tab-pane>
    </el-tabs>
    <!-- 老-查看详情 -->
    <show-content :visible.sync="isShowConetntModal" :title="rowInfo.recipesTitle" :content="rowInfo.recipesDetail" />

    <ele-modal v-for="(item,index) in modelList" :key="index" width="400px" :title="item.alertMessageType"
      @close="cancels(item,index)" custom-class="my-demo-dialog" :visible.sync="item.showDialog" :resizable="true"
      :maxable="true" :multiple="true" :move-out="false" :move-out-positive="true" position="rightBottom" top="0"
      :style="{marginRight:`${index*50}px`}">
      <div style="padding: 20px 0;font-size: 16px;">{{item.messageContent}}</div>
      <template v-slot:footer>
        <el-button @click="cancels(item,index)">取消</el-button>
        <el-button type="primary" @click="cancels(item,index)">确定</el-button>
      </template>
    </ele-modal>
    <audio ref="audio" src="./autio.mp3" preload="auto"></audio>
  </el-popover>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { getUnreadNotice } from '@/api/layout';
  import systemManager from "@/request/systemManager.js";
  import homeManager from "@/request/homeManager.js";
  import showContent from "@/components/TinymceEditor/ShowContent.vue"; //新-查看详情
  export default {

    components: { showContent },
    data () {
      return {
        // 是否显示
        visible: false,
        // 选项卡选中
        active: 'notice',
        // 未读数据
        notice: [],
        // 已读数据
        letter: [],
        // 待办数据
        todo: [],
        isNotReadMessageCount: 0, //未读消息数量
        setIntervalTime: null,
        isRead: 0,
        isShowConetntModal: false,
        rowInfo: {
          recipesTitle: '',
          recipesDetail: '',
        },
        visible3: false,
        modelList: [],
        isPlaying: false,
        noList: [],//存放获取到的数据
      };
    },
    created () {
      this.query();
    },
    mounted () {
      this.setIntervalTime = setInterval(async item => {
        if (this.$hasAnyPermission('messageCount')) {
          await this.messageCount()
        }
        if (this.$hasAnyPermission('messageList')) {
          await this.messageList(0)
        }
        if (this.$hasAnyPermission('searchAlertMessageList')) {
          await this.searchAlertMessageList()
          this.modelList.forEach(item => {
            item.showDialog = true
          })
          this.$forceUpdate()
          console.log(this.modelList)
          if (this.modelList.length > 0) {
            this.playSound()
          }
        }
      }, 180000)
    },
    computed: {
      // 通知标题
      noticeTitle () {
        return '未读';
      },
      // 私信标题
      letterTitle () {
        return '已读';
      },
      // 待办标题
      todoTitle () {
        return '待办' + (this.todo.length ? `(${this.todo.length})` : '');
      },
      // 未读数量
      unreadNum () {
        return this.$store.state.user.isNotReadMessageCount;
      },
      // 主题状态
    ...mapGetters(['user'])
    },
    methods: {
      //获取弹窗列表
      async searchAlertMessageList () {
        try {
          let { code, data } = await homeManager.searchAlertMessageList()
          if (code == 200) {
            this.modelList = data
            this.modelList.forEach(item => {
              item.showDialog = false
            });
          }
        } catch (error) {
          //
        }
      },
      //点击确定
      async rightInfo (item, index) {
        try {
          if (this.$hasAnyPermission('messageInfoById')) {
            let { code, data } = await homeManager.messageInfo({ id: item.id })
            if (code == 200) {
              this.modelList[index].showDialog = false
              this.$forceUpdate()
              //
            }
          } else {
            this.$message.success('暂无权限');
          }
        } catch (error) {
          //
        }
      },
      //关闭弹窗
      // closeDown (item,index) {
      //   this.modelList[index].showDialog = false
      //   this.$forceUpdate()
      // },
      //点击取消
      async cancels (item, index) {
        try {
          if (this.$hasAnyPermission('updateMessageInfoById')) {
            let { code, data } = await homeManager.updateMessageInfoById({ id: item.id })
            if (code == 200) {
              this.modelList[index].showDialog = false
              this.query()
              this.$forceUpdate()
              //
            }
          } else {
            this.$message.success('暂无权限');
          }
        } catch (error) {
          //
        }
      },
      //音频播放
      playSound () {
        if (!this.isPlaying) {
          const audio = this.$refs.audio;
          console.log(audio)
          audio.play();
          this.isPlaying = true;
          console.log('声音开启')
          // 播放完毕后重置标志
          audio.addEventListener('ended', () => {
            this.isPlaying = false;
            console.log('声音关闭')
          });
        }
      },
      async viewDetail (row) {
        try {
          let { code, data } = await homeManager.messageInfo({ id: row.id })
          if (code == 200) {
            this.visible = false
            this.rowInfo.recipesTitle = data.messageTitle
            this.rowInfo.recipesDetail = data.messageContent
            this.isShowConetntModal = true
            this.query()
          }
        } catch (error) {
          //
        }

      },
      tiveChange (e) {
        this.messageList(e.index)
        //this.isRead = e.index
      },
      //获取最近十条消息
      async messageList (index) {
        try {
          if (index == 0) {
            this.active = 'notice'
          }
          let { code, data } = await systemManager.messageList({ isRead: index })
          if (code == 200) {
            this.notice = data
          }
        } catch (error) {
          //
        }
      },
      //未读消息数量
      async messageCount () {
        try {
          let { code, data } = await systemManager.messageCount()
          if (code == 200) {
            this.$store.dispatch('user/setIsNotReadMessageCount', data.isNotReadMessageCount);
          }
        } catch (error) {
          //
        }
      },
      /* 查询数据 */
      async query () {
        if (this.$hasAnyPermission('messageCount')) {
          await this.messageCount()
        }
        if (this.$hasAnyPermission('sys:auth:user')) {
          await this.messageList(0)
        }
        if (this.$hasAnyPermission('searchAlertMessageList')) {
          await this.searchAlertMessageList()
          this.modelList.forEach(item => {
            item.showDialog = true
          })
          this.$forceUpdate()
          console.log(this.modelList)
          if (this.modelList.length > 0) {
            this.playSound()
          }
        }
        // getUnreadNotice().then((result) => {
        //   this.notice = result.notice;
        //   this.letter = result.letter;
        //   this.todo = result.todo;
        // }).catch((e) => {
        //   this.$message.error(e.message);
        // });
      },
      /* 清空通知 */
      clearNotice () {
        this.notice = [];
      },
      /* 清空通知 */
      clearLetter () {
        this.letter = [];
      },
      /* 清空通知 */
      clearTodo () {
        this.todo = [];
      }
    },
    // 生命周期 - 销毁之前
    beforeDestroy () {
      if (this.setIntervalTime) {
        clearInterval(this.setIntervalTime);
        this.setIntervalTime = null;
      }
    },
    // 生命周期 - 销毁完成
    destroyed () {
      if (this.setIntervalTime) {
        clearInterval(this.setIntervalTime);
        this.setIntervalTime = null;
      }
    },
  };
</script>

<style lang="scss">
  .ele-notice-group {
    display: block;

    .el-badge {
      line-height: 1;
      display: block;
    }
  }

  /* 消息通知pop */
  .ele-notice-pop {
    padding: 0 !important;

    /* tab */
    .el-tabs__nav-scroll {
      text-align: center;
    }

    .el-tabs__nav {
      float: none;
      display: inline-block;
    }

    .el-tabs__item {
      height: 44px;
      line-height: 44px;
      padding: 0 20px !important;
    }

    /* 空视图 */
    .ele-empty {
      padding: 100px 0;
    }
  }

  /* 列表 */
  .ele-notice-list {
    padding-top: 8px;
    max-height: 360px;
    overflow: auto;
  }

  .ele-notice-item {
    .ele-notice-item-wrapper {
      padding: 12px 15px;
      transition: background-color 0.2s;
      cursor: pointer;

      &:hover {
        background-color: hsla(0, 0%, 60%, 0.05);
      }
    }

    .ele-text-secondary {
      margin-top: 5px;
      font-size: 13px;
    }

    .ele-cell-desc {
      margin-top: 3px !important;
      font-size: 12px !important;
    }
  }

  .ele-notice-item-icon {
    width: 32px;
    height: 32px;
    line-height: 32px !important;
    color: #fff;
    font-size: 16px;
    background-color: #60b2fc;
    border-radius: 50%;
    text-align: center;

    &.el-icon-s-check {
      background-color: #f5686f;
    }

    &.el-icon-video-camera {
      background-color: #7cd734;
    }

    &.el-icon-s-claim {
      background-color: #faad14;
    }

    &.el-icon-message-solid {
      background-color: #2bcacd;
    }
  }

  /* 操作按钮 */
  .ele-notice-actions>.ele-cell-content {
    line-height: 42px;
    text-align: center;
    cursor: pointer;
    color: inherit;

    &:hover {
      background-color: hsla(0, 0%, 60%, 0.05);
    }
  }

  ::v-deep .my-demo-dialog.el-dialog {
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 0 !important;
  }
</style>