import BaseModule from './utils/BaseModule';

class BaseManager extends BaseModule {
  constructor() {
    super()
  }

  // 上传文件
  uploadfile (param, config = {}) {
    let paras = {
      files: param
    }
    return this.upload('/web/common/sysFile/uploads', paras, config)
  }
  //导出文件
  exportfile (param, requstUrl) {
    const paras = {
      ...param,
    }
    // console.log(`${this.baseURL}${requstUrl}?${this.qs.stringify(paras)}`)
    window.open(`${this.baseURL}${requstUrl}?${this.qs.stringify(paras)}`)
  }


  /**
   * 日志管理
   */
  //日志
  listvList (param) {
    return this.postJson('/web/userInfo/searchLogList', param)
  }
  //批量删除日志
  batchRemove (param) {
    return this.postJson('/web/userInfo/batchRemoveLog', param)
  }
  //日志删除
  listvRemove (param) {
    return this.postJson('/web/userInfo/removeLog', param)
  }

  //修改通知类型 
  updateMessageType(param){
    return this.postJson('/api/system/user/updateMessageTypeByUserId', param)
  }
  //获取未读消息数量
  messageCount(param){
    return this.postJson('/web/messageInfo/searchMyIsNotReadMessageCount', param)
  }

  //获取最近十条消息 
  messageList(param){
    return this.postJson('/web/messageInfo/searchMyIsNotReadMessageList', param)
  }

  //获取首页数据 
  indexStatisticsData(param){
    return this.postJson('/web/statistics/indexStatisticsData', param)
  }
  //获取订单统计数量 
  searchOrderList(param){
    return this.postJson('/web/statistics/searchOrderList', param)
  }

  //食谱排行列表 
  subscribeRankingList(param){
    return this.postJson('/web/statistics/searchRecipesSubscribeRankingList', param)
  }

  //最近一个月注册用户列表 
  registerCountList(param){
    return this.postJson('/web/statistics/searchUserRegisterCountListByCondition', param)
  }

  //取消原因列表
  cancelOrderingCountList(param){
    return this.postJson('/web/statistics/searchCancelOrderingCountList', param)
  }

}

export default new BaseManager()