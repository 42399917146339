
import CryptoJS from "crypto-js"
import { Base64 } from 'js-base64'
import { AES_KEY_16 } from '@/config/setting';
const KP = {
  key: AES_KEY_16, // 秘钥 16*n:
  // iv: $util.randomString(16) // 偏移量
};


function getAesString (data, key, iv) { // 加密
  key = CryptoJS.enc.Utf8.parse(key);
  iv = CryptoJS.enc.Utf8.parse(iv);
  // console.log('111--', key, iv)
  let encrypted = CryptoJS.AES.encrypt(data, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  // console.log('encrypted', encrypted)
  return base64UrlEncode(CryptoJS.enc.Base64.stringify(encrypted.ciphertext)); // 返回的是base64格式的密文
}
// 解密
function getDAesString (content, keyStr, ivStr = '') {
  var arr = content.split('__');
  var word = arr[0];
  var iv = '';
  const regex = new RegExp('_', 'g');
  word = word.replace(regex, '/').replace(/-/g, '+'); // 合并替换操作
  var key = CryptoJS.enc.Utf8.parse(keyStr);
  if (ivStr) {
    iv = dealWithIV(ivStr);
  } else {
    iv = dealWithIV(arr[1]);
  }
  iv = CryptoJS.lib.WordArray.create(iv);
  try {
    var decrypt = CryptoJS.AES.decrypt(word, key, {
      iv,
      mode: CryptoJS.mode.CBC, // 改为CBC模式
      padding: CryptoJS.pad.Pkcs7
    });
    return decrypt.toString(CryptoJS.enc.Utf8);
  } catch (error) {
    console.error("解密失败:", error);
    throw new Error('Failed to decrypt input string.');
  }
}

function dealWithIV (str) {
  if (!str || /[^A-Za-z0-9+/=]/.test(str)) throw new Error('Invalid input string');  // 确保输入字符串不为空且符合Base64的基本格式要求
  try {
    str = str.replace(/_/g, '/').replace(/-/g, '+');  // 替换特殊字符串，使得输入符合Base64解码要求
    const decodedBinary = atob(str);  // 将Base64字符串转换为二进制字符串
    const bytes = new Uint8Array(decodedBinary.length);  // 使用TextDecoder优化将二进制字符串转换为Uint8Array
    const decoder = new TextDecoder('utf-8', { fatal: true });
    for (let i = 0; i < decodedBinary.length; i++) {
      bytes[i] = decoder.decode(decodedBinary.slice(i, i + 1))[0];
    }
    return bytes;
  } catch (error) {
    throw new Error('Failed to decode input string: ' + error.message);  // 处理可能的解码异常
  }
}


// AES 对称秘钥加密
const aes = {
  en: (data, iv) => getAesString(data, KP.key, iv),
  de: (data, iv) => getDAesString(data, KP.key, iv)
};

// BASE64
const base64 = {
  en: (data) => CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(data)),
  de: (data) => CryptoJS.enc.Base64.parse(data).toString(CryptoJS.enc.Utf8)
};
// BASE64URL
const base64url = {
  en: (data) => CryptoJS.enc.Base64url.stringify(CryptoJS.enc.Utf8.parse(data)),
  de: (data) => CryptoJS.enc.Base64url.parse(data).toString(CryptoJS.enc.Utf8)
};


// 对象中的值进行Base64编码
function encodeObjectValues (obj) {
  return Object.keys(obj).reduce((acc, key) => {
    acc[key] = base64url.en(obj[key]);
    return acc;
  }, {});
}

// 对象中的值进行Base64解码
function decodeObjectValues (obj) {
  return Object.keys(obj).reduce((acc, key) => {
    acc[key] = base64url.de(obj[key]);
    return acc;
  }, {});
}

function base64UrlEncode (str_val) {
  return str_val.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '')
}



/**
 * CryptoJS 加密
 *
 * @param {String} encryptData  需要加密数据
 * @returns 加密后的数据
 * @memberof Utils
 */
function encrypt (encryptData) {
  var key = CryptoJS.enc.Utf8.parse('as-Crypto-js')
  var srcs = CryptoJS.enc.Utf8.parse(encryptData)
  var encrypted = CryptoJS.AES.encrypt(srcs, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  })
  return encrypted.toString();
}

/**
 * CryptoJS 解密
 *
 * @param {String} encryptData  需要加密数据
 * @returns 解密后的数据
 * @memberof Utils
 */
function decrypt (encryptData) {
  var key = CryptoJS.enc.Utf8.parse('as-Crypto-js')
  var decrypt = CryptoJS.AES.decrypt(encryptData, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  })
  return CryptoJS.enc.Utf8.stringify(decrypt).toString();
}




export {
  aes,
  base64,
  base64url,
  encodeObjectValues,
  decodeObjectValues,
  encrypt,
  decrypt
};