<template>
  <elm-drawer :title="title" :visible="visible" maskClosable @update:visible="updateVisible">
    <div class="html-content tinymce-editor-custom" v-html="content" @click="clickHandleLinkBtn"></div>

    <template v-slot:footer>
      <el-button @click="updateVisible(false)">关闭</el-button>
    </template>
  </elm-drawer>
</template>
<script>
export default {
  props: {
    visible: Boolean,  // 弹窗是否打开
    title: {
      type: String,
      default: "提示",
    },
    content: [String, Number],
  },
  methods: {

    /* 更新visible */
    updateVisible (value) {
      this.$emit('update:visible', value);
    },

    // 点击富文本内事件
    clickHandleLinkBtn (event) {
      const href = event.target.getAttribute('href');
      if (href) {
        event.preventDefault();  // 阻止默认行为，防止页面跳转
        window.open(href, '_blank');  // 使用window.open打开链接
      }
    }

  }
};
</script>

<style scoped>

.html-content {
  white-space: pre-wrap;
}
.html-content >>> img {
  max-width: 100%;
  height: auto !important;
}
</style>



<!-- <elm-drawer :visible="visible" :append-to-body="true" :close-on-click-modal="false" custom-class="ele-dialog-form" :title="title" centered @update:visible="updateVisible" >
  <div class="html-content editor-custom" v-html="content"></div>

  <template v-slot:footer>
    <el-button @click="updateVisible(false)">关闭</el-button>
  </template>
</elm-drawer> -->