<template>
  <a-drawer class="elm-drawer" :title="title" :width="width" :visible="visible" :height="height" :zIndex="zIndex" :placement="placement" :closable="closable" :mask="mask" :destroyOnClose="destroyOnClose" :keyboard="keyboard" :maskClosable="maskClosable" @close="updateVisible(false)">
    <div class="elm-drawer__body">
      <slot></slot>
    </div>
    <div ref="drawerFooter" class="elm-drawer__footer" v-if="$slots.footer">
      <slot name="footer"></slot>
    </div>
  </a-drawer>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'
import Vue from 'vue';
import { Drawer } from 'ant-design-vue';
Vue.use(Drawer);

import props from "./props";
export default {
  // import引入的组件需要注入到对象中才能使用
  name: 'ElmDrawer',
  props,
  data () {
    // 这里存放数据
    return {

    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () { },
  // 方法集合
  methods: {

    /* 更新visible */
    updateVisible (value) {
      this.$emit('update:visible', value);
      this.$emit('close', value);
    },


  },
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建之前
  beforeCreate () { },
  // 生命周期 - 挂载之前
  beforeMount () { },
  // 生命周期 - 更新之前
  beforeUpdate () { },
  // 生命周期 - 更新之后
  updated () { },
  // 生命周期 - 销毁之前
  beforeDestroy () { },
  // 生命周期 - 销毁完成
  destroyed () { },
  // 如果页面有keep-alive缓存功能，这个函数会触发
  activated () { },
}
</script>

<style scoped lang="scss">
// @import 'ant-design-vue/dist/antd.css';
@import './index.css';
.elm-drawer {
  .elm-drawer__body {
    overflow: auto;
    padding: 18px 24px 14px;
    flex: 1;
  }
  .elm-drawer__footer {
    // position: absolute;
    // left: 0;
    // bottom: 0;
    border-top: 1px solid var(--border-color-lighter);
    padding: 10px 16px;
    text-align: right;
    background: var(--popover-background-color);
    border-radius: 0px 0px 4px 4px;
  }
}

/* 弹出列表 */
.elm-drawer-list {
  // ::v-deep .ant-drawer-content {
  //   background: var(--layout-body-background);
  //   // .ant-drawer-wrapper-body {
  //   //   .ant-drawer-body {}
  //   // }
  // }

  .elm-drawer__body {
    padding: 0px;
  }
  ::v-deep .ele-body {
    padding: 0px;
    .el-card{
      margin-top: 0px;
    }
  }
}
</style>
