const props = {
  visible: {   //Drawer 是否可见
    type: Boolean,
    default: false
  },
  title: {  //标题
    type: String,
    default: ''
  },
  width: {   //宽度
    type: [String, Number],
    default: "30%"
  },
  height: {    //高度, 在 placement 为 top 或 bottom 时使用
    type: [String, Number],
    default: "256"
  },
  zIndex: {   //设置 Drawer 的 z-index
    type: Number,
    default: 1000
  },
  placement: {   //抽屉的方向 'top' | 'right' | 'bottom' | 'left'
    type: String,
    default: 'right',
  },
  closable: {   //是否显示右上角的关闭按钮
    type: Boolean,
    default: true
  },
  mask: {  //是否展示遮罩
    type: Boolean,
    default: true
  },
  destroyOnClose: {    //关闭时销毁 Drawer 里的子元素
    type: Boolean,
    default: false
  },
  keyboard: {   //是否支持键盘 esc 关闭
    type: Boolean,
    default: true
  },
  maskClosable: {   //点击蒙层是否允许关闭
    type: Boolean,
    default: false
  },
  maskStyle: {   //遮罩样式
    type: Object,
    default: () => { return {} }
  },
  wrapClassName: {    //对话框外层容器的类名
    type: String,
    default: ''
  },
  wrapStyle: {  //可用于设置 Drawer 最外层容器的样式，和 drawerStyle 的区别是作用节点包括 mask
    type: Object,
    default: () => { return {} }
  },
  drawerStyle: {  //用于设置 Drawer 弹出层的样式
    type: Object,
    default: () => { return {} }
  },
  headerStyle: {  //用于设置 Drawer 弹出层的样式
    type: Object,
    default: () => { return {} }
  },
  bodyStyle: {   //可用于设置 Drawer 内容部分的样式
    type: Object,
    default: () => { return {} }
  },
  // afterVisibleChange: Function,  //切换抽屉时动画结束后的回调

};
export {
  props as default
};
