<!-- 全局页脚 -->
<template>
  <div class="ele-text-center p-l-10 elm-admin-footer">
    <div class="ele-text-secondary">{{ $t('layout.footer.copyright') }}</div>
  </div>
</template>

<script>
  export default {};
</script>
