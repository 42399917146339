// 导出页面为PDF格式
import html2Canvas from 'html2canvas'
import JsPDF from 'jspdf'


function isSplit(nodes, index, pageHeight) {
  // 计算当前这块dom是否跨越了a4大小，以此分割
  if (nodes[index].offsetTop + nodes[index].offsetHeight < pageHeight && nodes[index + 1] && nodes[index + 1].offsetTop + nodes[index + 1].offsetHeight > pageHeight) {
    return true;
  }
  return false;
}
function uploadPdf() {
  let ST = document.documentElement.scrollTop || document.body.scrollTop;
  let SL = document.documentElement.scrollLeft || document.body.scrollLeft;
  document.documentElement.scrollTop = 0;
  document.documentElement.scrollLeft = 0;
  document.body.scrollTop = 0;
  document.body.scrollLeft = 0;
  //获取滚动条的位置并赋值为0，因为是el-dialog弹框，并且内容较多出现了纵向的滚动条,截图出来的效果只能截取到视图窗口显示的部分,超出窗口部分则无法生成。所以先将滚动条置顶
  const A4_WIDTH = 592.28;
  const A4_HEIGHT = 841.89;
  let imageWrapper = document.querySelector(".div") // 获取DOM
  let pageHeight = imageWrapper.scrollWidth / A4_WIDTH * A4_HEIGHT;
  let lableListID = imageWrapper.querySelectorAll("p");
  // 进行分割操作，当dom内容已超出a4的高度，则将该dom前插入一个空dom，把他挤下去，分割
  for (let i = 0; i < lableListID.length; i++) {
    let multiple = Math.ceil((lableListID[i].offsetTop + lableListID[i].offsetHeight) / pageHeight);
    if (this.isSplit(lableListID, i, multiple * pageHeight)) {
      let divParent = lableListID[i].parentNode; // 获取该div的父节点
      let newNode = document.createElement('div');
      newNode.className = 'emptyDiv';
      newNode.style.background = '#ffffff';
      let _H = multiple * pageHeight - (lableListID[i].offsetTop + lableListID[i].offsetHeight);
      //留白
      newNode.style.height = _H + 30 + 'px';
      newNode.style.width = '100%';
      let next = lableListID[i].nextSibling; // 获取div的下一个兄弟节点
      // 判断兄弟节点是否存在
      if (next) {
        // 存在则将新节点插入到div的下一个兄弟节点之前，即div之后
        divParent.insertBefore(newNode, next);
      } else {
        // 不存在则直接添加到最后,appendChild默认添加到divParent的最后
        divParent.appendChild(newNode);
      }
    }
  }

}
export default {
  install(Vue) {
    Vue.prototype.getPdf = function (title) {
      if (title == "学生二维码" || title == "活动分析报告") {
        let imageWrapper = document.querySelector('#maindiv');
        html2Canvas(imageWrapper, {
          allowTaint: true,
          dpi: window.devicePixelRatio * 4,
          scale: 4
        }).then(function (canvas) {
          const pdf = new JsPDF('p', 'mm', 'a4'); // A4纸，纵向
          const ctx = canvas.getContext('2d');
          const a4w = 170; const a4h = 267; // A4大小，210mm x 297mm，四边各保留20mm的边距，显示区域170x257
          const imgHeight = Math.floor(a4h * canvas.width / a4w); // 按A4显示比例换算一页图像的像素高度
          let renderedHeight = 0;

          while (renderedHeight < canvas.height) {
            const page = document.createElement('canvas');
            page.width = canvas.width;
            page.height = Math.min(imgHeight, canvas.height - renderedHeight);// 可能内容不足一页

            // 用getImageData剪裁指定区域，并画到前面创建的canvas对象中
            page.getContext('2d').putImageData(ctx.getImageData(0, renderedHeight, canvas.width, Math.min(imgHeight, canvas.height - renderedHeight)), 0, 0);
            pdf.addImage(page.toDataURL('image/jpeg', 1.0), 'JPEG', 20, 15, a4w, Math.min(a4h, a4w * page.height / page.width)); // 添加图像到页面，保留10mm边距

            renderedHeight += imgHeight;
            if (renderedHeight < canvas.height) {
              pdf.addPage();// 如果后面还有内容，添加一个空页
            }
            // 预览pdf(这里我用的是事件总线把canvas传递过去展示，达到模拟pdf预览的效果，有用但效果不是很好，有需要的可以自行修改)
            //this.$EventBus.$emit('open-pdf', canvas);
          }
          // 保存文件
          pdf.save(`${title}.pdf`);

        })
      } else if (title == "活动报告" || title == "教育局报告"){
        let imageWrapper = document.querySelector('#maindiv');
        html2Canvas(imageWrapper, {
          allowTaint: true,
          dpi: window.devicePixelRatio * 4,
          scale: 4
        }).then(function (canvas) {
          const pdf = new JsPDF('p', 'mm', 'a4'); // A4纸，纵向
          const ctx = canvas.getContext('2d');
          const a4w = 170; const a4h = 267; // A4大小，210mm x 297mm，四边各保留20mm的边距，显示区域170x257
          const imgHeight = Math.floor(a4h * canvas.width / a4w); // 按A4显示比例换算一页图像的像素高度
          let renderedHeight = 0;

          while (renderedHeight < canvas.height) {
            const page = document.createElement('canvas');
            page.width = canvas.width;
            page.height = Math.min(imgHeight, canvas.height - renderedHeight);// 可能内容不足一页

            // 用getImageData剪裁指定区域，并画到前面创建的canvas对象中
            page.getContext('2d').putImageData(ctx.getImageData(0, renderedHeight, canvas.width, Math.min(imgHeight, canvas.height - renderedHeight)), 0, 0);
            pdf.addImage(page.toDataURL('image/jpeg', 1.0), 'JPEG', 20, 15, a4w, Math.min(a4h, a4w * page.height / page.width)); // 添加图像到页面，保留10mm边距

            renderedHeight += imgHeight;
            if (renderedHeight < canvas.height) {
              pdf.addPage();// 如果后面还有内容，添加一个空页
            }
            // 预览pdf(这里我用的是事件总线把canvas传递过去展示，达到模拟pdf预览的效果，有用但效果不是很好，有需要的可以自行修改)
            //this.$EventBus.$emit('open-pdf', canvas);
          }
          // 保存文件
          pdf.save(`${title}.pdf`);

        })
      }else {
        let imageWrapper = document.querySelector('#maindiv');
        html2Canvas(imageWrapper, {
          allowTaint: true,
          // dpi: window.devicePixelRatio*3,
          // scale:3
        }).then(function (canvas) {
          let contentWidth = canvas.width
          let contentHeight = canvas.height
          let pageHeight = contentWidth / 592.28 * 841.89
          let leftHeight = contentHeight * 0.81
          let position = 841.89 * 0.25
          let imgWidth = 595.28
          let imgHeight = 592.28 / contentWidth * contentHeight
          let pageData = canvas.toDataURL('image/jpeg', 0.8)
          let PDF = new JsPDF('', 'pt', 'a4')
          var num = 0;
          if (leftHeight < pageHeight) {
            PDF.addImage(pageData, 'JPEG', imgWidth * 0.1, 0, imgWidth * 0.8, imgHeight * 0.8)
            num++
          } else {

            while (leftHeight > 0) {
              PDF.addImage(pageData, 'JPEG', imgWidth * 0.1, position, imgWidth * 0.8, imgHeight * 0.8)
              leftHeight -= pageHeight
              position -= 841.89
              if (leftHeight > 0) {
                PDF.addPage()
              }
              num++
            }
          }
          PDF.save(title + '.pdf')
        }
        )
      }
    }
  }
}