// BaseModule.js
import axios from 'axios'
import qs from 'qs'
import router from '@/router'
import { Message, MessageBox } from 'element-ui';
import { API_MIAN_URL, TOKEN_HEADER_NAME, LAYOUT_PATH, version, from, MD5_KEY } from '@/config/setting';
import { getToken, setToken } from '@/utils/token-util';
import { logout } from '@/utils/page-tab-util';
import { utilsfn } from '@/scripts/utilsfn.js';
import md5 from 'js-md5';
let net_error_message = '服务异常'

class BaseModule {
  constructor() {
    this.$http = axios.create({ baseURL: API_MIAN_URL })
    this.baseURL = API_MIAN_URL;
    this.access_token = () => getToken();
    this.qs = qs;
    this.geneMethod = (url = '') => {
      return {
        get: param => this.get(`${url}/listv`, param),
        add: param => this.post(`${url}/save`, param),
        update: param => this.post(`${url}/update`, param),
        remove: param => this.post(`${url}/remove`, param)
      }
    }
    this.$http.interceptors.request.use(function (config) {  // 设置请求头
      config.headers.common['version'] = version;
      config.headers.common['from'] = from;
      config.headers.common['timestamp'] = String(new Date().getTime());
      config.headers.common['sign'] = md5([version, from, config.headers.common.timestamp, MD5_KEY].join('&')).toUpperCase();
      if (config.url != 'login') config.headers.common[TOKEN_HEADER_NAME] = getToken() && ('Bearer ' + getToken());
      console.log("%c%s", "color: white; background: green; font-size: 14px;", '请求参数:', config.url, config.data || config.params)
      return config;
    }, function (error) {  // 对请求错误做些什么
      return Promise.reject(error);
    });
    this.$http.interceptors.response.use(function (response) {  // 对响应数据做点什么
      console.log("%c%s", "color: red; background: yellow; font-size: 14px;", '请求结果:', response.config.url, response.data)
      if (response.config.notShowMsg) return response.data;
      switch (Number(response.data.code)) {
        case 200:
          break;
        default:
          if (response.config.url != "/common/log/list") Message.error(response.data.message || net_error_message);
          break;
      }
      return response.data;
    }, function (error) {
      console.log("%c%s", "color: yellow; background: red; font-size: 14px;", '失败结果:', error.config.url, error)
      try {
        switch (error.response.status) {
          case 401:
            var currentPath = router.currentRoute.path;
            if (currentPath === LAYOUT_PATH) {
              logout(true);
            } else {
              MessageBox.alert('登录状态已过期, 请退出重新登录!', '系统提示', {
                confirmButtonText: '重新登录',   //确定按钮的文本内容
                showClose: false,  //MessageBox 是否显示右上角关闭按钮
                callback: (action) => {
                  if (action === 'confirm') logout(false, currentPath);
                },
                beforeClose: () => { MessageBox.close(); }
              });
            }
            break;
          default:
            Message.error(error.response.data.message || error.response.data.error || net_error_message);
            break;
        }
      } catch (err) {
        Message.error(net_error_message);
      }
      return Promise.reject(error.response.data);
    });
    this.dataMethodDefaults = {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded', },
      transformRequest: [function (data) { return qs.stringify(data) }]
    }
  }

  get (url, params = undefined, config = {}) {
    return this.$http.get(url, { params }, config)
  }
  post (url, data = undefined, config = {}) {
    return this.$http.post(url, data, { ...this.dataMethodDefaults, ...config })
  }
  postJson (url, data = undefined, config = {}) {    //'Content-Type': 'application/json',传参类型 使用
    return this.$http.post(url, data ? utilsfn.dealWithEncryptionParameters(data) : data, config)
  }
  upload (url, data = undefined, config = {}) {
    let formData = new FormData();
    if (data) {
      if (Array.isArray(data.files)) {
        data.files.forEach(i => { formData.append('files', i) });
        delete data.files;
        for (let key in data) { formData.append(key, data[key]) }
      } else {
        for (let key in data) { formData.append(key, data[key]) }
      }
    }
    return this.$http.post(url, formData, { ...config })
  }
  put (url, data = undefined, config = {}) {
    return this.$http.put(url, data, { ...this.dataMethodDefaults, ...config })
  }
  delete (url, config = {}) {
    return this.$http.delete(url, config)
  }
}

export default BaseModule