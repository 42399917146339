/**
 * axios 实例
 */
import axios from 'axios';
import router from '@/router';
import { MessageBox } from 'element-ui';
import { API_BASE_URL, TOKEN_HEADER_NAME, LAYOUT_PATH, version, from, MD5_KEY } from '@/config/setting';
import { getToken, setToken } from './token-util';
import { logout } from './page-tab-util';
import md5 from 'js-md5';

const service = axios.create({
  baseURL: API_BASE_URL
});

/**
 * 添加请求拦截器
 */
service.interceptors.request.use((config) => {
  const token = getToken();  // 添加 token 到 header
  config.headers.common['version'] = version;
  config.headers.common['from'] = from;
  config.headers.common['timestamp'] = new Date().getTime();
  config.headers.common['sign'] = md5([version, from, String(new Date().getTime()), MD5_KEY].join('&')).toUpperCase();
  if (token && config.headers && config.url != 'login') config.headers.common[TOKEN_HEADER_NAME] = getToken() && ('Bearer ' + getToken());    //token;
  return config;
}, (error) => {
  return Promise.reject(error);
});

/**
 * 添加响应拦截器
 */
service.interceptors.response.use((res) => {
  // 登录过期处理
  if (res.data.code === 401) {
    const currentPath = router.currentRoute.path;
    if (currentPath === LAYOUT_PATH) {
      logout(true);
    } else {
      MessageBox.alert('登录状态已过期, 请退出重新登录!', '系统提示', {
        confirmButtonText: '重新登录',
        callback: (action) => {
          if (action === 'confirm') logout(false, currentPath);
        },
        beforeClose: () => {
          MessageBox.close();
        }
      });
    }
    return Promise.reject(new Error(res.data.message));
  }
  const token = res.headers[TOKEN_HEADER_NAME.toLowerCase()];  // token 自动续期
  if (token) setToken(token);
  return res;
}, (error) => {
  if (error.response.status == 401) {
    var currentPath = router.currentRoute.path;
    if (currentPath === LAYOUT_PATH) {
      logout(true);
    } else {
      MessageBox.alert('登录状态已过期, 请退出重新登录!', '系统提示', {
        confirmButtonText: '重新登录',   //确定按钮的文本内容
        showClose: false,  //MessageBox 是否显示右上角关闭按钮
        callback: (action) => {
          if (action === 'confirm') logout(false, currentPath);
        },
        beforeClose: () => { MessageBox.close(); }
      });
    }
  }
  return Promise.reject(error);
});

export default service;
