import { aes, base64, encodeObjectValues } from '@/scripts/cryptoUtil'
export const utilsfn = {
  formatTime (dateTime = 0, fmt = 'yyyy-mm-dd') {
    if (dateTime === null || dateTime == 0) return '--';
    if (!dateTime) dateTime = Number(new Date());  // 如果为null,则格式化当前时间
    if (dateTime.toString().length == 10) dateTime *= 1000;  // 如果dateTime长度为10或者13，则为秒和毫秒的时间戳，如果超过13位，则为其他的时间格式
    let date = new Date(dateTime);
    let ret;
    let opt = {
      "y+": date.getFullYear().toString(), // 年
      "m+": (date.getMonth() + 1).toString(), // 月
      "d+": date.getDate().toString(), // 日
      "h+": date.getHours().toString(), // 时
      "M+": date.getMinutes().toString(), // 分
      "s+": date.getSeconds().toString() // 秒
      // 有其他格式化字符需求可以继续添加，必须转化成字符串
    };
    for (let k in opt) {
      ret = new RegExp("(" + k + ")").exec(fmt);
      if (ret) fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")));
    }
    return fmt;
  },
  /**
   * 获取某个日期是周几
   */
  getDayOfWeek (dateString = 0, fmt = 'short') {
    const date = new Date(dateString);
    const dayOfWeek = date.getDay();
    const formatter = new Intl.DateTimeFormat('zh-CN', { weekday: 'short' });  //指定星期几的格式（"narrow-日"、"short-周日"或"long-星期日"）。
    return formatter.format(date);
  },
  // 将驼峰转为下划线
  camelToUnderline (obj) {
    let newObj = {}
    Object.entries(obj).forEach(([key, value]) => {
      newObj[key.replace(/([A-Z])/g, "_$1").toLowerCase().replace(/_i_d/g, '_id')] = value
    })
    return newObj
  },
  // 处理select框 lable值为空
  toSelectLableStr (str_value) {
    let str_data_value = (str_value ? encodeURI(str_value) : '--').replace(/&nbsp;/g, '%20');
    return decodeURI(str_data_value);
  },
  /**
   * 数字转成汉字
   * @params num === 要转换的数字
   * @return 汉字
   * */
  toChinesNum (num) {
    let changeNum = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']
    let unit = ['', '十', '百', '千', '万']
    num = parseInt(num)
    let getWan = (temp) => {
      let strArr = temp.toString().split('').reverse()
      let newNum = ''
      let newArr = []
      strArr.forEach((item, index) => { newArr.unshift(item === '0' ? changeNum[item] : changeNum[item] + unit[index]) })
      let numArr = []
      newArr.forEach((m, n) => { if (m !== '零') numArr.push(n); })
      if (newArr.length > 1) {
        newArr.forEach((m, n) => {
          if (newArr[newArr.length - 1] === '零') {
            if (n <= numArr[numArr.length - 1]) newNum += m;
          } else {
            newNum += m
          }
        })
      } else {
        newNum = newArr[0]
      }
      return newNum
    }
    let overWan = Math.floor(num / 10000)
    let noWan = num % 10000
    if (noWan.toString().length < 4) noWan = '0' + noWan;
    return overWan ? getWan(overWan) + '万' + getWan(noWan) : getWan(num)
  },

  // 处理html标签
  getHandleHtmlLabel (label_val) {
    if (label_val.indexOf('<p>') == -1) {
      return `<p>${label_val}</p>`
    } else {
      return label_val
    }
  },
  /**
   * 保留两位小数
   * @param {*} value  值
   * @param {*} point  几位小数
   * @returns  结果
   */
  fmtToFix (value = 0, point = 2, isMend = 1, isSplit = 0) {
    value = Number(value)
    if (isMend) return isSplit ? value.toFixed(point).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : value.toFixed(point); //此处2为保留两位小数
    return isSplit ? parseFloat(value.toFixed(point)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : parseFloat(value.toFixed(point)); //此处2为保留两位小数
  },

  /**
   * 处理平台价格
   * @param {*}  
   * @returns 
   */
  fmtToPrice (value = 0, point = 2, isMend = 1, isSplit = 0) {
    return `${this.fmtToFix(value || 0, point, isMend, isSplit)}元`
  },


  // 随机字符串
  randomString (len = 23) {
    var chars = "abcdefhijkmnprstwxyzABCDEFGHJKMNPQRSTWXYZ12345678";
    var str = "";
    for (var i = 0; i < len; i++) str += chars.charAt(Math.floor(Math.random() * chars.length));
    return str;
  },
  // 参数加密处理
  dealWithEncryptionParameters (data) {
    console.log('加密前参数', JSON.stringify(data));
    const iv_str = this.randomString(16) //加密向量
    return {
      ivString: base64.en(iv_str),
      encodeString: aes.en(JSON.stringify(encodeObjectValues(data)), iv_str),
    }
  },




}