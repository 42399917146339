<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { updateDocumentTitle } from '@/utils/document-title-util';

export default {
  name: 'App',
  created () {
    this.$store.dispatch('theme/recoverTheme');  // 恢复主题
  },
  methods: {
    /* 路由切换更新浏览器标题 */
    setDocumentTitle () { updateDocumentTitle(this.$route, (key) => this.$t(key), this.$store.state.theme.tabs); },
  },
  watch: {
    '$i18n.locale' () {
      this.setDocumentTitle();
    },
    $route () {
      this.setDocumentTitle();
    }
  }
};
</script>